<template>
  <div>
    <eden-page-header
      :title="'Companies Directory'"
      :subtitle="'Add new company'"
    />
    <company-form :action="'add'" />
  </div>
</template>

<script>
import CompanyForm from "@/components/Logistics/Directory/Companies/CompanyForm.vue";
import * as actions from "@/store/action-types";

export default {
  name: "CompanyAdd",
  components: { CompanyForm },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
  },
};
</script>

<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form" class="mt-4 pt-4">
      <!-- <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column is-align-center">
              <eden-image-upload
                :image-url.sync="form.image"
                :image-text="'company logo'"
                :image-folder="'logistics/companies'"
              />
            </el-col>
          </el-row>
        </template>
      </eden-form-section> -->
      <eden-form-section
        title="Company Details"
        subtitle="Information about this company."
      >
        <template slot="form-fields">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Company name"
                prop="name"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.name"
                  placeholder="Enter company name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Email address"
                prop="company_email"
                :rules="validateEmail()"
              >
                <el-input
                  type="text"
                  v-model="form.company_email"
                  placeholder="you@mail.coom"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Address"
                prop="address"
                :rules="validateField()"
              >
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="form.address"
                  placeholder="Enter company address"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Director Information"
        subtitle="Information about the director at this company."
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="contact_firstname"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.contact_firstname"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="contact_lastname"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.contact_lastname"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Email address"
                prop="contact_email"
                :rules="validateEmail()"
              >
                <el-input
                  type="text"
                  v-model="form.contact_email"
                  placeholder="you@mail.coom"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Phone number"
                prop="contact_phone"
                :rules="validatePhone()"
              >
                <eden-phone-input :input.sync="form.contact_phone" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="cancel">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              >Add Company
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import companies from "@/requests/logistics/directory/companies";

export default {
  name: "CompanyForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    company: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        image: "",
        name: "",
        company_email: "",
        address: "",
        contact_firstname: "",
        contact_lastname: "",
        contact_email: "",
        contact_phone: "",
      },
      adding: false,
      updating: false,
    };
  },

  created() {
    if (this.action === "edit") {
      this.loading = true;
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.company[key];
      });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },

  methods: {
    getTargetRoute(routeName) {
      return routeName.includes("marketplace")
        ? "marketplace-logistics.directory"
        : "logistics.directory";
    },
    cancel() {
      const targetRoute = this.getTargetRoute(this.currentRoute);

      this.$router.push({
        name: `${targetRoute}.index`,
        query: { t: "companies" },
      });
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        companies
          .add(this.form)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              const targetRoute = this.getTargetRoute(this.currentRoute);

              this.$router.push({
                name: `${targetRoute}.index`,
                query: { t: "companies" },
              });
              setTimeout(() => {
                this.$router.go(0);
              }, 100);
            }
            this.adding = false;
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = {
          company_id: this.company.id,
          ...this.form,
        };
        companies
          .update(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              const targetRoute = this.getTargetRoute(this.currentRoute);
              this.$router.push({
                name: `${targetRoute}.company`,
                params: { id: this.company.id },
              });
              setTimeout(() => {
                this.$router.go(0);
              }, 100);
            }
            this.updating = false;
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
